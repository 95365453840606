@import '../../styles//variables';

.btn-round {
  border-radius: 30px !important;
}
.buttons {
  margin-top: 30px;

  @media only screen and (max-width: $bp-small) {
    display: flex;
    justify-content: space-evenly;
  }

  @media only screen and (max-width: $bp-smallest) {
    justify-content: space-between;
  }

  .btn {
    padding: 10px 20px;
  }

  .btn-round {
    border-radius: 30px;
  }

  .btn-outline {
    border: 2px solid;
  }

  .btn-opacity {
    opacity: 0.4;
  }

  .btn-active {
    color: white;
    background-color: var(--primary-color-1);
    border-color: var(--primary-color-1);
  }

  .btn-static {
    width: 140px;
  }

  .group-button {
    button:first-child {
      padding-right: 50px;
    }

    button:last-child {
      margin-left: -40px;
    }
  }

  .btn-exit {
    height: 43px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    border: 2px solid var(--primary-color-1-o4);
    color: var(--primary-color-1-o8);
    background-color: white;
    width: 133px;

    &:hover {
      border-width: 2px;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .btn-exit-cancel {
    height: 43px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    border: 2px solid var(--primary-color-1);
    width: 141px;
    float: right;
    box-shadow: none;

    &:hover {
      border: inherit;
    }

    &:focus {
      outline: none;
    }
  }
  .btn-rubric-retry {
    height: 44px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    border: 2px solid var(--primary-color-1);
    color: var(--primary-color-1);
    width: 130px;
    padding-left: 26.5px;
    svg {
      margin-left: 12px;
      overflow: visible;
      g {
        stroke-width: 2.5px;
        transform: translate(0, 2px);
      }
    }
    &:focus {
      outline: none;
    }
    &:hover {
      border-width: 2px;
      text-decoration: none;
    }
  }
  .btn-rubric-dashboard {
    width: 180px;
    height: 44px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    box-shadow: none;
    float: right;
    border: 2px solid var(--primary-color-1);
    svg {
      margin-left: 12px;
      overflow: visible;
      g {
      }
    }
    &:focus {
      outline: none;
    }
    &:hover {
      border-width: 2px;
      text-decoration: none;
    }
  }

  .btn-rubric-prime {
    width: 231px;
    height: 44px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    box-shadow: none;
    float: right;
    margin-left: 20px;
    svg {
      margin-left: 12px;
      overflow: visible;
      g {
      }
    }
    &:focus {
      outline: none;
    }
    &:hover {
      border-width: 2px;
      text-decoration: none;
    }
  }

  .btn-submit-cancel {
    height: 43px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    border: 2px solid var(--primary-color-1-o4);
    color: var(--primary-color-1-o8);
    background-color: white;
    width: 133px;

    &:hover {
      border-width: 2px;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .btn-submit-proceed {
    height: 43px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    border: 2px solid var(--primary-color-1);
    width: 141px;
    float: right;
    box-shadow: none;

    &:hover {
      border: inherit;
    }

    &:focus {
      outline: none;
    }

    svg {
      margin-left: 12px;
    }
  }

  .btn-color-primary {
    color: white;
    background: var(--primary-color-1);
    border: 2px solid var(--primary-color-1);
    font-weight: 600;
    font-size: 16px;
    padding: 12px 30px;
  }

  .btn-outline {
    background: white;
    color: var(--primary-color-1);
  }

  .btn-cancel {
    height: 43px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    background-color: white;
    border: 2px solid rgba(74, 132, 255, 0.6);
    color: rgba(74, 132, 255, 0.8);
    width: 103px;

    @media only screen and (max-width: $bp-small) {
      width: 7.5rem;
      height: 2.5rem;
    }

    &:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
      border: 2px solid rgba(74, 132, 255, 1);
      color: rgba(74, 132, 255, 1);
    }
  }

  .btn-exam {
    height: 43px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    border: 2px solid var(--primary-color-1);
    width: 160px;

    &:hover {
      border-width: 2px;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .btn-learn {
    height: 43px;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
    border: 0;
    width: 10rem;
    float: right;

    &-name {
      &-sm {
        display: none;
      }
    }

    @media only screen and (max-width: $bp-small) {
      width: 7.5rem;
      height: 2.5rem;

      &-name {
        display: none;
        &-sm {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &:focus {
      outline: none;
    }

    svg {
      margin-left: 12px;
    }
  }

  .question-exit {
    height: 32px;
    width: 85px;
    border-width: 2px;
    text-transform: capitalize;
    line-height: 16px;
    color: rgba(74, 132, 255, 0.6);
    &:hover {
      border-color: rgba(74, 132, 255, 0.6);
      border-width: 2px;
    }
    &:focus {
      outline: none;
    }
  }
  .question-proceed {
    height: 32px;
    width: 164px;
    border-width: 2px;
    text-transform: capitalize;
    line-height: 16px;
    box-shadow: none;
    float: right;
    &.disabled-button {
      background-color: rgba(74, 132, 255, 0.1);
      color: white;
    }
    &:hover {
      outline: none;
    }
    &:focus {
      outline: none;
    }
    svg {
      margin-left: 20px;
    }
  }
}
.btn-rubric-share {
  &.btn-round,
  &.MuiButton-root,
  &.MuiButton-outlinedPrimary {
    height: 44px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    border: 2px solid var(--primary-color-1);
    color: var(--primary-color-1);
    width: 224px;
    @media (max-width: 1200px) {
      font-size: 14px;
    }
    svg {
      margin-left: 12px;
      overflow: visible;

      g {
        stroke-width: 3.5px;
      }
    }

    &:focus {
      outline: none;
    }

    &:hover {
      border-width: 2px;
      text-decoration: none;
    }
  }
}

.btn-hint {
  width: 100%;
  text-transform: none;

  @media only screen and (max-width: $bp-smallest) {
    font-weight: 400;
    font-size: 0.9rem;
  }
}

.btn-hint-ok {
  &.btn-round,
  &.MuiButton-root,
  &.MuiButton-outlinedPrimary {
    height: 43px;
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
    border: 2px solid var(--primary-color-1);
    width: 486px;
    float: right;
    box-shadow: none;

    &:hover {
      border: inherit;
    }

    &:focus {
      outline: none;
    }
  }
}

.question-next {
  .content-next & {
    box-shadow: none;
    height: 52px;
    width: 52px;
    min-width: inherit;
    border-radius: 26px;
  }

  &:focus {
    outline: none;
  }
}

.question-next-disabled {
  .content-next &[class*='disabled'] {
    box-shadow: none;
    height: 52px;
    width: 52px;
    border-radius: 26px;
    min-width: inherit;
    border: 2px solid rgba(74, 132, 255, 0.3);

    svg {
      fill: rgba(74, 132, 255, 0.3);
    }
  }

  &:focus {
    outline: none;
  }
}

.question-prev {
  .content-prev & {
    box-shadow: none;
    height: 52px;
    width: 52px;
    min-width: inherit;
    border-radius: 26px;
    border-width: 2px;
    border-color: rgba(74, 132, 255, 1);
  }

  &:focus {
    outline: none;
  }
}

.question-prev-disabled {
  .content-prev &[class*='disabled'] {
    box-shadow: none;
    height: 52px;
    width: 52px;
    border-radius: 26px;
    min-width: inherit;
    border-width: 2px;
    border-color: rgba(74, 132, 255, 0.3);

    svg {
      fill: rgba(74, 132, 255, 0.3);
    }
  }

  &:focus {
    outline: none;
  }
}
