.bodycontent {
  color: #ffffff;
  text-align: center;
  padding: 14px 14px;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;
  margin-left: 55px;
}

/* Floating homepage container settings */
#homeleft {
  float: left;
  margin-left: 1px;
}
#homeright {
  float: right;
  margin-right: -10%;
}

@media screen and (max-width: 1250px) {
  #homeleft {
    float: none;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .hubspot {
    width: 100%;
  }
  #homeright {
    float: none;
    margin-bottom: 10px;
    width: 100%;
  }
  .reframeimage {
    height: auto;
    width: 394px;
    overflow: hidden;
  }
}

$vertical-listing-line-halfsize: 3px;
$vertical-listing-dot-radius: 16px;

.navbar-nav {
  flex-direction: row;
}

.main {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

.primary_btn {
  border: none;
  background-color: var(--primary-color-1);
  color: var(--neutral-color-2);
  font-size: 1rem;
  font-weight: bold;
  width: 200px;
  height: 60px;

  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  &:hover {
    background-color: rgb(51, 92, 178);
  }
}

.circle {
  height: 60px;
  border-radius: 30px;
}

// Add custom slider settings

@media (max-width: 1200px) {
  .primary_btn {
    font-size: 12px;
    width: 170px;
    height: 45px;
    padding-top: 0;
  }

  .section-untiring-patients.section,
  .section-instant-gratification.section {
    h2 {
      font-size: 33px;
    }
    h3 {
      font-size: 22.5px;
    }
    ul.vertical-listing {
      li {
        font-size: 22.5px;
      }
    }
  }
}
