@import '../../../styles/variables';

.custom_modal {
  &-bottom {
    display: flex;
    align-items: flex-end;
  }

  .custom_modal_content {
    background-size: contain;
    width: auto;
    height: auto;
    border-radius: 1em;
    position: relative;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
      border-radius: 16px;
    }

    @media only screen and (max-width: $bp-small) {
      height: 100%;
    }
  }
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  &.overlay {
    background-image: url('../../../assets/images/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
  }
  &_dialog {
    animation: fadeIn 0.5s ease-in;
    margin: 0 auto;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  &_dialog.bottom {
    animation: slideUp 0.5s ease-out;
    align-items: flex-end;
  }
  &_dialog.centered:before {
    content: '';
    height: calc(100vh - 2rem);
    display: block;

    @media only screen and (max-width: $bp-small) {
      height: calc(100vh - 4rem);
    }
  }
  &_content {
    position: relative;
    width: 100%;
    height: 100%;
    background: white;
  }
}

.show {
  display: block;
}
