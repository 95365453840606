.container {
  display: flex;
  flex-direction: row;
  width: calc(100% - 55px); //mind the sidebar width
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.left-menu-wrapper {
  min-height: 100vh;
  @media only screen and (min-width: 769px) {
    width: 0px;
  }

  @media only screen and (max-width: 768px) {
    width: 0px;
  }
  @media screen and (max-width: 576px) {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 0px;
  }
}

.main-wrapper {
  padding: 45px;
  min-height: 100vh;

  @media only screen and (min-width: 1440px) {
    width: calc(100% - 0px);
  }

  @media only screen and (min-width: 1200px) and (max-width: 1439px) {
    width: calc(100% - 38px - 0px);
  }

  @media only screen and (max-width: 1200px) {
    padding-left: 22.5px;
    padding-right: 22.5px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1199px) {
    width: calc(100% - 0px);
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: calc(100% - 0px);
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    margin-top: 113px;
    padding-top: 5px;
  }
}

.right-col {
  display: none;
}
