@import '../../../styles/variables';

.message-out {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  flex: 0 1;
  z-index: 0;
  margin-bottom: 12px;
  &-avatar {
    margin-left: 10px !important;
    background-color: var(--primary-color-1) !important;
  }
  &__icon {
    max-width: 35px;
    margin-left: 10px;
  }
  .message {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  p {
    position: relative;
    top: 0;
    z-index: 111111;
    padding: 0.7rem 1rem;
    border-radius: 0.7rem;

    font-size: 16px;
    margin: 0;

    color: var(--secondary-color-2);
    background-color: white;

    box-shadow: 0px 2px 3px rgba(27, 28, 29, 0.03);
    max-width: 70%;
    width: fit-content;
    animation: message-p-animation 0.4s;
    animation-timing-function: cubic-bezier(0, 0, 0, 0);
    transform: translateZ(0);

    @media only screen and (max-width: $bp-smallest) {
      padding: 0.5rem 0.6rem;
      font-size: 0.95rem;
    }
  }
  .sb-avatar {
    width: 40px;
    height: 40px;
    margin-left: 0.625rem;
    animation: avatar-animation 0.4s linear;
    transform: translateZ(0);

    @media only screen and (max-width: $bp-smallest) {
      margin-left: 0.5rem;
    }
  }

  &__guide {
    p {
      background-color: #cdecf9;
      font-style: italic;
      color: #002348;
      opacity: 0.6;
    }
  }

  &__compound {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    animation: message-p-animation 0.5s;
    animation-timing-function: cubic-bezier(0, 0, 0, 0);

    &__msg {
      max-width: 70%;
      background-color: white;
      padding: 0.7rem 1rem;
      border-radius: 0.7rem;
      margin: -7px 45px 0.6rem 0;
      @media only screen and (max-width: $bp-small) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: -4px;
      }
    }

    &__btn {
      background-color: #e6f6fc !important;
      box-shadow: none !important;
      color: var(--secondary-color-2) !important;
      padding: 8px 15px !important;
      margin: 0 5px !important;
      font-size: 0.95rem !important;
      user-select: text !important;

      @media only screen and (max-width: $bp-small) {
        margin: 5px !important;
        font-size: 0.9rem !important;
      }

      &:hover {
        background-color: #83d0f0 !important;
      }

      &.Mui-disabled {
        opacity: 0.7;
      }

      &-active {
        background-color: #83d0f0 !important;
      }
    }
  }
}

@keyframes message-p-animation {
  from {
    transform: translate(0, 5vh);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes avatar-animation {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.message-out__learnMode__intro {
  opacity: 0.4;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.81;
  letter-spacing: normal;
  text-align: right;
  color: #002348;
  text-align: right;
  margin-right: 48px;
  margin-top: 10px;
  margin-bottom: 24px;
}
