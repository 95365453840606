// todo: seperate only the styles that is required in this component
@import '../../../containers/Case/buttons.scss';
@import '../../../styles/variables';

.case__modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  padding-top: 1rem;

  &__icons {
    display: flex;

    &-patient {
      margin-left: 1rem;
    }
  }

  &__large {
    width: 35rem;
    border-bottom: 1.25rem solid var(--label-failure-color);
    border-radius: 1rem;

    @media only screen and (max-width: $bp-small) {
      width: 26.5rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      width: 19.8125rem;
    }
  }

  &__img {
    align-self: center;
    margin-top: 3rem;

    @media only screen and (max-width: $bp-small) {
      margin-top: 2rem;
      width: 9rem;
      height: 9rem;
    }

    &-patient {
      margin-top: 0;
    }
    &--custom {
      width: 200px;
      align-self: center;
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1.25rem 5.2rem 0 5.2rem;
    text-align: center;
    &-text {
      font-size: 21px;
      font-weight: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #002348;
    }

    &-text-hint {
      text-transform: uppercase;
    }

    @media only screen and (max-width: $bp-small) {
      margin: 1.25rem 2rem 0 2rem;
    }
  }

  &__subtitle {
    margin: 1.25rem 5.2rem 29px 5.2rem;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    color: #072b61;

    &-hint {
      margin: 0.5rem 2.5rem 0 2.5rem;
      font-size: 1.1rem;
    }

    @media only screen and (max-width: $bp-small) {
      margin: 0.5rem 1rem 0 1rem;

      &-hint {
        font-size: 0.95rem;
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    margin-top: 0px !important;
    margin-bottom: 60px;

    &-patient {
      justify-content: center;
    }

    &-cancel {
      width: 10rem !important;
    }

    @media only screen and (max-width: $bp-small) {
      margin: 0.5rem 2rem 2rem 2rem;

      &-cancel {
        width: 7.5rem !important;
      }
    }

    @media only screen and (max-width: $bp-smallest) {
      margin: 0.5rem 1rem 2rem 1rem;
    }
  }
}
