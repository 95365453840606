@import '../../../styles/variables';

.case__level__badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  color: var(--secondary-color-2);

  @media only screen and (max-width: $bp-small) {
    width: 1.1rem;
    height: 1.1rem;
  }

  &-mobile {
    width: 1.1rem;
    height: 1.1rem;
    margin-right: 5px;
  }

  &-header {
    font-size: 0.5rem;
    margin-top: 0.5rem;
    text-transform: uppercase;

    @media only screen and (max-width: $bp-small) {
      display: none;
    }

    &-mobile {
      display: none;
    }
  }

  &-level {
    font-size: 1rem;
    font-weight: 500;
    margin-top: -0.4rem;

    @media only screen and (max-width: $bp-small) {
      font-size: 0.7rem;
      font-weight: 400;
      margin-top: 0;
    }

    &-mobile {
      font-size: 0.7rem;
      font-weight: 400;
      margin-top: 0;
    }
  }
}
