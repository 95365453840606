@import '../../../styles/variables';

.instant-gratification-overlay-1 {
  position: absolute;
  left: 0;
  bottom: -220px;
  max-width: 170;
  z-index: -1;
  @media only screen and (max-width: $bp-smallest) {
    display: none;
  }
}

h3 {
  color: var(--grey-chateau-color);
  margin: 40px 0px;
}

.oscer-screenshot {
  margin: 0 auto 95px auto;
  position: relative;
  width: 70.8%;

  .oscer-product {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    box-shadow: 0px 6px 21px rgba(7, 42, 68, 0.1);
    width: 100%;
  }

  .feature {
    box-shadow: 0px 4px 24px rgba(123, 123, 123, 0.3);
    transition: all 0.3s cubic-bezier(0.25, 0.25, 0.25, 1);
  }

  .feature:hover {
    box-shadow: 0px 6px 28px rgba(123, 123, 123, 0.6);
  }

  .oscer-info-modal {
    border-radius: 1em;
    position: absolute;
    left: -16%;
    top: 6%;
    width: 23.5%;
  }

  .oscer-product-score {
    border-radius: 2em;
    position: absolute;
    left: -7.8%;
    top: 50.7%;
    width: 28.5%;
  }

  .oscer-product-hint {
    border-radius: 1em;
    position: absolute;
    left: -20.833%;
    top: 68%;
    width: 32.083%;
  }

  .oscer-product-questions {
    border-radius: 1em;
    position: absolute;
    right: 0;
    top: 25%;
    width: 25%;
    transform: translate(48%);
  }

  .oscer-mobile-stats-bar {
    border-radius: 3em;
    position: absolute;
    right: 0;
    top: 56%;
    width: 25%;
    transform: translate(65%);
  }

  .oscer-hint-modal {
    border-radius: 13.8px;
    position: absolute;
    right: 0;
    top: 67%;
    width: 23%;
    transform: translate(17%);
  }
}
