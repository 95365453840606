@import './animation';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

* {
  font-family: 'Roboto', sans-serif;
}

:root {
  // --primary-color: #1acdc7;
  // --primary-1-color: rgba(26, 205, 199, 0.1);
  // --primary-4-color: rgba(26, 205, 199, 0.4);
  // --background-color: #eeeeee;
  // --color-message-in: #9046ff;
  // --color-message-out: #ffffff;
  --border-color-1: #d1d1d1;
  --border-color-2: #ededed;

  --primary-color-1: #4a84ff;
  --primary-color-1-o4: rgba(74, 132, 255, 0.4);
  --primary-color-1-o8: rgba(74, 132, 255, 0.8);
  --primary-color-2: rgb(131, 208, 240);
  --primary-color-2-o3: rgba(131, 208, 240, 0.3);
  --primary-color-2-o35: rgba(131, 208, 240, 0.3);
  --primary-color-2-o2: rgba(131, 208, 240, 0.2);
  --primary-color-2-o1: rgba(131, 208, 240, 0.1);
  --primary-color-3-o8: rgba(88, 142, 255, 0.8);

  --secondary-color-1: #9dc9f6;
  --secondary-color-2: #002348;
  --secondary-color-2-o1: rgba(0, 35, 72, 0.1);
  --secondary-color-2-o4: rgba(0, 35, 72, 0.4);
  --background-color: rgba(74, 132, 255, 0.1);
  --background-color-2: rgba(216, 216, 216, 0.3);
  --background-color-avatar: #d8d8d8;
  --background-color-2-o35: rgba(216, 216, 216, 0.35);
  --background-color-3: rgba(157, 201, 246, 0.6);
  --background-color-4: rgba(246, 200, 159, 0.1);
  --background-color-5: rgba(251, 167, 153, 0.1);

  --text-color: #002348;

  --neutral-color-1: #fbfbfb;
  --neutral-color-2: #ffffff;

  --case-1-color: #3fd9c0; // Level 1 to 3
  --case-2-color: #f3e1a5; // Level 4 to 6
  --case-3-color: #e67f7f; // Level 7 to 9
  --case-4-color: #d1d1d1; // Level 10 to 12

  --response-na-color: #fdebcc;
  --response-failure-color: #fdd3cc;
  --label-na-color: #f5c060;
  --label-failure-color: #fba799;

  --black-1-color: #121313;
  --black-4-color: rgba(18, 19, 19, 0.4);
  --black-2-color: rgba(18, 19, 19, 0.2);
  --black-5-color: rgba(18, 19, 19, 0.05);
  --black-8-color: rgba(18, 19, 19, 0.8);
  --black-3-color: rgba(27, 28, 29, 0.03);

  --red-color: #fb4b4b;

  --blue-color: rgb(104, 210, 78);

  --yellow-color: #ffce31;

  --cardiology-color: #fba799;
  --respiratory-color: #bbd9f9;
  --gastrointestinal-color: #f4e3ad;
  --neurology-color: #d1d1d1;
  --renal-color: #a99df6;
  --endocrine-color: #b1e3c6;
  --musculoskeletal-color: #fbc9c2;
  --heme-color: #726e89;
  --dermatology-color: #fce2cc;
  --crown-color: #f6c89f;
  --grey-chateau-color: #919aa3;
  --left-col-width: 21.27%;
  --light-blue-color: #b6cdff;
}

$system-colors: (
  cardiology: #fba799,
  respiratory: #9dc9f6,
  gastrointestinal: #f4e3ad,
  neurology: #d1d1d1,
  renal: #a99df6,
  endocrine: #b1e3c6,
  musculoskeletal: #fbc9c2,
  heme: #726e89,
  dermatology: #fce2cc,
  crown: #f6c89f,
);

@mixin color-modifiers($attribute: 'color') {
  @each $name, $hex in $system-colors {
    &-#{$name} {
      #{$attribute}: $hex;
    }
  }
}

.color {
  @include color-modifiers($attribute: 'color');
}

.bg {
  @include color-modifiers($attribute: 'backgroud-color');
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);

  // Set customised scroll bar
  ::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.8rem;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 1rem;
    border-radius: 1rem;
    background-color: var(--background-color-avatar);
    -webkit-box-shadow: inset -1px -1px 0px var(--background-color-avatar),
      inset 1px 1px 0px var(--background-color-avatar);
    box-shadow: inset -1px -1px 0px var(--background-color-avatar), inset 1px 1px 0px var(--background-color-avatar);
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

html {
  box-sizing: border-box;
}

button:focus {
  outline: none;
}

a:hover {
  text-decoration: none;
}

.pusher {
  display: flex;
  overflow: hidden;
  background: #edf2ff;
  min-height: 100%;
  // height: 100%;
  @media only screen and (max-width: 599px) {
    // Todo: Firefox android may not suppport stretch component
    height: 100%;
    // min-height: stretch;
    // min-height: 70vh;
    // background: yellowgreen;
  }
}

@media (max-width: 767px) {
  .signin-button {
    margin-top: 10px !important;
  }
}

.application {
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: white;
  }
}
