.animated-btn {
  position: relative;
  &_circle {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &_circle:after {
    content: '';
    position: absolute;
    display: block;
    border-radius: 50%;
    border-style: solid;
    border-width: 0px;
    border-color: currentColor;
  }
  &_circle.animated:after {
    animation: click-animation 0.4s ease-in-out;
  }
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: background-color, box-shadow, border;
  transition-duration: 250ms, 250ms, 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms, 0ms, 0ms;
  box-shadow: 0 10px 50px 0 rgba(7, 42, 68, 0.12);
}
