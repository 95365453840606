.StudentsReview {
  .slick-slide + div {
    // margin-top: 20px;
    margin: 20px auto;

    @media (max-width: 599px) {
      margin: 0;
    }
  }

  .slick-slide.slick-active.slick-current + div {
    margin: 20px auto 10px auto;
    @media (max-width: 599px) {
      transform: none;
      margin: 0;
    }
  }
}
