@import '../buttons';
@import '../../../styles/variables';
.case__modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  padding-top: 1rem;
  &__begin-switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    &-voice {
      text-align: center;
      margin: 0 15px;
      @media only screen and (max-width: $bp-small) {
        font-size: 14px;
      }
    }
  }
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    width: 350px;
  }
  &__submit {
    &__error {
      &__offset {
        margin-top: -34px !important;
      }
      text-align: center;
      color: var(--label-failure-color);
      margin-bottom: 1rem;
      margin-top: -24px;
    }
  }
  &__icons {
    display: flex;
    &-patient {
      margin-left: 1rem;
    }
  }
  &__large {
    width: 35rem;
    border-bottom: 1.25rem solid var(--label-failure-color);
    border-radius: 1rem;
    @media only screen and (max-width: $bp-small) {
      width: 26.5rem;
    }
    @media only screen and (max-width: $bp-smallest) {
      width: 19.8125rem;
    }
  }
  &__img {
    align-self: center;
    margin-top: 3rem;
    height: 250px;
    @media only screen and (max-width: $bp-small) {
      margin-top: 2rem;
      width: 144px;
      height: 144px;
    }
    &-patient {
      margin-top: 0;
    }
  }
  &__title {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1.25rem 7.2rem 0 7.2rem;
    text-align: center;
    &-text-hint {
      text-transform: uppercase;
    }
    @media only screen and (max-width: $bp-small) {
      margin: 1.25rem 2rem 0 2rem;
    }
  }

  &__okay {
    text-align: center;
    margin-bottom: 2.8rem;
    font-weight: 300;
    @media only screen and (max-width: $bp-small) {
      margin-top: -5px;
    }
  }

  &__subtitle-text {
    text-align: center;
    margin: 8px 50px 0 50px;
    font-weight: 300;
    min-height: 112px;
    &-hint {
      margin: 0.5rem 2.5rem 0 2.5rem;
      font-size: 1.1rem;
    }
    @media only screen and (max-width: $bp-small) {
      margin: 0.5rem 1rem 0 1rem;
      &-hint {
        font-size: 0.95rem;
      }
    }
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    margin: 0 6rem;
    margin-top: 0.8rem !important;
    margin-bottom: 2rem;
    &-cancel {
      width: 10rem !important;
    }
    &__exit {
      width: 10rem !important;
    }
    @media only screen and (max-width: $bp-small) {
      margin: 0.5rem 2rem 2rem 2rem;
      &-cancel {
        width: 7.5rem !important;
      }
    }
    @media only screen and (max-width: $bp-smallest) {
      margin: 0.5rem 1rem 2rem 1rem;
    }
  }
}
.buttons.case__modal__btns.case__modal__btns-patient {
  justify-content: space-between;
}

.audio_subtitle-text {
  text-align: center;
  margin: 8px 50px 0 50px;
  font-weight: 300;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: $bp-small) {
    margin: 0.5rem 1rem 0 1rem;
  }
}

.audio_title {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1.25rem 5.2rem 0 5.2rem;
  text-align: center;
  @media only screen and (max-width: $bp-small) {
    margin: 1.25rem 2rem 0 2rem;
  }
}
