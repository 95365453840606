@import '../../../styles/variables';

.button__confirm {
  &,
  &:link,
  &:visited {
    background-color: rgb(74, 132, 255);
    min-width: 130px;
    height: 40px;
    border: none;
    font-size: 16px;
    border-radius: 24px;
    padding: 5px 16px;
    color: white;
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s;
    text-transform: capitalize;
    font-weight: 500;
    border: solid 2px transparent;
  }

  &:hover {
    background-color: rgb(89, 126, 206);
  }

  &:active {
    outline: none;
    background-color: rgb(51, 92, 178);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &-exit {
    &,
    &:link,
    &:visited {
      border: 2px solid rgba(252, 185, 173, 0.5);
      color: #fcb9ad;
      background-color: white;
    }

    &:hover {
      border: 2px solid #fab9ad;
      background-color: rgba(247, 199, 191, 0.1);
    }

    &:active {
      background-color: rgba(247, 199, 191, 0.8);
    }
  }

  &-normal {
    &,
    &:link,
    &:visited {
      background-color: white;
      color: var(--primary-color-1);
      border: solid 2px var(--primary-color-1);
    }

    &:hover {
      background-color: rgb(247, 247, 247);
    }

    &:active {
      background-color: rgb(241, 241, 241);
    }
  }
}
