// .dashboard-row.row,
// .dashboard-col {
//   margin: 0;
//   padding: 0;
// }

// .dashboard.container-fluid {
//   width: calc(100% - 55px);
//   padding: 0;
//   margin: 0;
// }
// @media (max-width: 1200px) {
// }
// @media (max-width: 576px) {
//   .dashboard.container-fluid {
//     width: 100%;
//   }
// }

@import '../../styles/variables';

.dashboard {
  display: flex;
  max-width: calc(100% - 55px);
  width: 100%;
  // height: 100%;
  // overflow: auto;

  @media only screen and (max-width: $bp-small) {
    max-width: 100%;
    overflow: auto;
  }

  &__main {
    flex: 4;
    max-width: 80%;
    @media only screen and (max-width: $bp-medium) {
      max-width: calc(100% - 5.5rem);
    }
    @media only screen and (max-width: $bp-small) {
      max-width: 100%;
      min-height: 100vh;
    }
    @media only screen and (max-width: 576px) {
      max-width: 100%;
    }
  }

  &__right-side-bar {
    flex: 1;
    max-width: 20%;
  }

  @media only screen and (max-width: $bp-medium) {
    &__right-side-bar {
      width: 5.5rem;
    }
  }

  @media only screen and (max-width: $bp-small) {
    &__right-side-bar {
      position: absolute;
      right: 0;
    }
  }
}

.main {
  &_board {
    &-content {
      padding: 0px 60px;
      padding-top: 15px;

      @media only screen and (max-width: $bp-large) {
        padding: 0 20px;
      }

      @media only screen and (max-width: $bp-small) {
        padding: 0 1rem;
        padding-bottom: 20px;
      }

      h1 {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 20px;
        margin-top: 45px;
        color: var(--secondary-color-2);

        @media only screen and (max-width: $bp-small) {
          display: none;
        }
      }
    }
  }
}
