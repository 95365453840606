.dashboard-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--neutral-color-2);
  z-index: 1010;
  border-radius: 20px;

  .inner {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 1009;
    background-color: var(--neutral-color-2);
    border-radius: 20px;
  }
}

.dashboard-modal-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
