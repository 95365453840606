.start-btn {
  width: 50px;
  height: 50px;
  position: relative;
  font-size: 22px;
  border: none;
  border-radius: 50%;
  border-color: var(--color-message-in);

  background-color: var(--primary-color-1);
  color: white;

  outline: none !important;
  transition: all 0.5s linear;
  transform-origin: center;
}

.start-btn::after {
  content: '';
  position: absolute;
  display: block;
  border-radius: 50%;
  border: 0px solid;
}

.start-btn.animated::after {
  animation: click-animation 0.7s ease-in-out;
}

.start-btn.animated {
  transition-delay: 0.6s;
  border: 1px solid var(--color-message-in);
  transform: scale(0);
  opacity: 0;
}
