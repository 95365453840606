@import '../../styles/variables';

.message-list {
  position: relative;
  width: 100%;
  padding-top: 2rem;
  padding-right: 1rem;
  padding-left: 1.5rem;
  scrollbar-color: var(--background-color-avatar) transparent;
  scrollbar-width: thin;

  &::after {
    content: '';
    display: block;
    padding: 3.5rem;
  }

  @media only screen and (max-width: 599px) {
    // background-color: yellow;
    // height: calc(100vh - 180px);

    padding: 82px 0.3rem 0 0.8rem;
    margin-bottom: 20px;
    &::after {
      padding: 1.6rem;
    }
  }
}
.message-list::-webkit-scrollbar {
  background: transparent;
  width: 6px;
}
.message-list::-webkit-scrollbar-thumb {
  background: var(--background-color-avatar);
  border-radius: 3px;
}

.message-box-wrapper {
  height: 90%;
  overflow-y: auto;
}

html,
body,
#app,
.application {
  height: 100%;
}
