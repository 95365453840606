@import '../../../styles/variables';

.main {
  margin-left: -8px;
  position: relative;
  margin-top: -1rem;

  @media only screen and (max-width: $bp-small) {
    margin-top: 0;
  }

  margin .case-mode {
    width: var(--left-col-width);
    height: 60px;
    position: absolute;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    background-color: var(--secondary-color-1);
    text-align: center;
    padding: 18px 0;
    z-index: 20;
    transition: transform 1s;
    h4 {
      margin: 0;
      font-size: 20px;
    }
    &.hide {
      transform: translate3d(calc(-100% + 30px), 0, 0);
    }
    &.show {
      transform: translate3d(0, 0, 0);
    }
  }
  .main-area {
    height: calc(100vh - 5.625rem);
    background: #edf2ff;

    @media only screen and (max-width: 599px) {
      height: auto;
      overflow: hidden;
    }

    .message-box {
      width: calc(100% - #{var(--left-col-width)});
      position: relative;
      height: 100%;
      float: left;
      left: var(--left-col-width);
      transition: all 1s;
      padding-right: 0.6rem;

      @media only screen and (max-width: 599px) {
        padding-right: 0;
        // background: red;
        height: auto;
      }

      &.expand {
        width: calc(100% - 30px);
        left: 30px;
      }
      &.full {
        width: 100%;
        left: 0;
      }
    }
  }
}
