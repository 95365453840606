@import '../../../styles/variables';

.message-in {
  display: none;
  transform-origin: 30px bottom;
  margin-bottom: 15px;

  &__text__container {
    cursor: pointer;
    &:hover,
    &.clicked {
      padding-left: 40px;
      &::after {
        content: url(../../../assets/images/flag-answer-feature-white.svg);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: calc(100% - 16px);
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 8px;
        left: 0;
      }
    }
  }

  &__main {
    display: flex;
    color: #000000;

    &__voice {
      cursor: pointer;
      margin-left: 10px;
    }
  }

  &__tooltip {
    margin-top: 10px !important;
    &__arrow {
      left: 5px !important;
    }
  }
  img {
    width: 40px;
    height: 40px;
  }
  &__pre__img {
    margin-top: 24px;
    @media only screen and (max-width: 599px) {
      // margin-top: 2px;
      margin-top: 18px;
    }
  }
  &_text {
    display: flex;
    flex-direction: column;
    // max-width: 75%;
    width: 100%;
    justify-content: flex-end;

    margin-left: 0.625rem;
    transform-origin: bottom left;

    @media only screen and (max-width: $bp-smallest) {
      margin-left: 0.3rem;
    }

    p {
      align-self: flex-start;
      background-color: var(--primary-color-2);
      color: var(--secondary-color-2);
      margin-bottom: 0.6rem;
      padding: 0.7rem 1rem;
      border-radius: 0.7rem;
      box-shadow: 0px 2px 3px rgba(27, 28, 29, 0.03);
      position: relative;
      // cursor: pointer;
      max-width: 75%;

      @media only screen and (max-width: $bp-small) {
        max-width: 80%;
      }

      @media only screen and (max-width: $bp-smallest) {
        // max-width: 100%;
        padding: 0.5rem 0.6rem;
        font-size: 0.95rem;
        margin-bottom: 0;
      }
    }
    .message-in-icon {
      color: #d8d8d8;
      margin-left: -10px;
    }
    .footer {
      // margin-left: 0.5rem;
      height: 24px;
      margin-right: 45px;
      // margin-top: -5px;
      align-self: flex-end;
      @media only screen and (max-width: 599px) {
        // margin-top: 2px;
        height: 20px;
      }
      label {
        font-size: 0.95rem;
        color: var(--secondary-color-2);
        opacity: 0.4;
      }
      label.na {
        color: var(--label-na-color);
        opacity: 1;
      }
      label.failure {
        color: var(--label-failure-color);
        opacity: 1;
      }
      &__score {
        color: var(--primary-color-2);
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 599px) {
          font-size: 13px;
        }
        &--highlight {
          color: var(--primary-color-1);
        }

        &__icon {
          &--highlight {
            background-color: var(--primary-color-1) !important;
          }
        }

        &__diagnosis {
          margin-right: 10px;
          @media only screen and (max-width: 599px) {
            margin-top: -10px;
          }
        }
      }
      span {
        color: white;
        background-color: var(--primary-color-2);
        border-radius: 50%;
        margin-left: 5px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 22px;
        width: 22px;
        @media only screen and (max-width: 599px) {
          font-size: 12px;
          height: 18px;
          width: 18px;
        }
      }
    }
  }
  &_text.animated {
    animation: loading-message 0.3s ease-in-out;
  }
  p.clicked {
    &::after {
      content: url(../../../assets/images/flag-answer-feature.svg);
    }
  }
  p.success {
    background-color: var(--primary-color-2);
  }
  p.na {
    background-color: var(--response-na-color);
  }
  p.failure {
    background-color: var(--response-failure-color);
  }
}

.message-in.visible {
  display: flex;
  animation: show-message 0.3s ease-in;
}

@keyframes loading-message {
  from {
    transform: translate(0, 2vh);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes show-message {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
