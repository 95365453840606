.help-contact-card {
  width: 100%;
  background-color: var(--neutral-color-2);
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  padding: 10px 36px 30px 36px;
  color: var(--secondary-color-2);
  font-family: Roboto, sans-serif;

  .help-contact a {
    color: var(--secondary-color-2);
  }

  table {
    width: 100%;

    thead {
      font-size: 13.5px;
      font-weight: 600;

      tr {
        height: 34px;
        border-bottom: 1.5px solid var(--primary-color-1);

        td {
          width: 50%;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1.5px solid var(--background-color-2-o35);

        td {
          height: 45px;
          width: 50%;
          font-size: 12px;
          line-height: 1.13;
        }

        td:first-of-type {
          opacity: 0.4;
        }

        td:last-of-type {
          padding-left: 5px;
          font-weight: 500;
          text-decoration: underline;
          cursor: pointer;
          svg {
            margin-right: 11px;
          }
        }
      }
    }
  }
}

.help-card {
  @media screen and (min-width: 996px) {
    width: calc(50% - 5px);
  }
  @media screen and (max-width: 995px) {
    width: 100%;
  }
  background-color: var(--neutral-color-2);
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  padding: 10px 36px 30px 36px;
  color: var(--secondary-color-2);
  font-family: Roboto, sans-serif;

  ul {
    list-style-type: none;

    li:first-of-type {
      // header
      font-size: 13.5px;
      font-weight: 600;
      height: 34px;
      border-bottom: 1.5px solid var(--primary-color-1);
      margin-bottom: 10px;
    }
  }
}

.help-item-li {
  display: flex;
  flex-direction: column;
  border-bottom: 1.5px solid var(--background-color-2-o35);

  .help-line-header {
    display: flex;
    flex-direction: row;
    height: 34px;
    line-height: 34px;
    margin-top: 10px;

    .help-item {
      font-size: 12px;
      height: 34px;
      line-height: 34px;
      opacity: 0.4;
    }

    .help-item-expand {
      margin-left: auto;
      color: var(--primary-color-1);
      cursor: pointer;
      height: 34px;
      line-height: 34px;
    }
  }

  .help-content {
    &.collapsed {
      display: none;
    }
  }
}

.help-cards-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
