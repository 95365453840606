.section-swipe-right {
  background: url('../../../assets/home/section-swipe-right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 143px;

  @media (max-width: 768px) {
    display: none;
  }

  .slider-wrapper {
    margin-bottom: 40px;
  }
  h2 {
    margin: 60px 0px 40px 0px;
  }

  h3 {
    color: var(--grey-chateau-color);
    padding: 0 25%;
  }

  // The previous selector (.slick-active ~ .slick-active) affects both the middle and the last elements
  // This selector affects only the lat one
  // So this selector is dedicated to undo anything from previous selector,
  // This way, the result of both selectors will affect only the middle element
  // (not sure if this is the best way to go, but I find it is better than hacking on react level)
  .slick-active ~ .slick-active ~ .slick-active {
    .slide {
      width: 100% !important;
      margin-left: 0;

      .basic-wrapper,
      .hovered-wrapper {
        margin-top: 0;

        .display-content,
        .content-container {
          height: 100%;
        }
      }
      .card-item-details {
        h3 {
          margin-top: 0;
          margin-bottom: 4px;
          font-size: 32px;
        }
        p {
          font-size: 24px;
        }
        h4 {
          margin-top: 0;
          margin-bottom: 6px;
          font-size: 24px;
        }
        .presentation {
          font-size: 30px;
        }
      }
    }
  }
  .slick-active ~ .slick-active ~ .slick-active {
    .homepage-case-card-wrapper {
      .basic-wrapper {
        display: none;
      }
      .hovered-wrapper {
        display: block;
        .case-card-item {
          background-color: var(--secondary-color-1);
          color: white;
        }
      }
    }
  }
  .slide {
    outline: none;
    padding: 2em 2em;
    transition: transform 0.3s linear;

    .case-card-wrapper {
      width: 100%;
    }
  }

  .homepage-slider {
    max-width: calc(100%);
    margin: auto;
  }

  .carousel-detail {
    color: var(--grey-chateau-color);
    display: inline-block;
    font-size: 1em;
    padding-top: 0.5em;
    text-align: center;
    vertical-align: top;
    width: 33.33%;

    &.carousel-detail-1 {
      padding-right: 4em; // Remove padding between carousel items
    }

    &.carousel-detail-2 {
      padding-top: 2.5em; // Add space for enlarged item
    }

    &.carousel-detail-3 {
      padding-left: 4em; // Remove padding between carousel items
    }
  }
}
.homepage-slider {
  width: calc(100% + 6em);
  margin: 0 -3em;
  // Enlarge center item
  .slick-track {
    .slick-list {
      .slick-track {
        .slick-center {
          .slide {
            overflow: hidden;
            transform: scale(1.17);
          }
        }
      }
    }
  }

  .slick-arrow {
    background-color: var(--neutral-color-2);
    border-radius: 50%;
    box-shadow: -4px 4px 7px -2px #d8d8d8;
    width: 3.5rem;
    height: 3.5rem;
    padding: 1rem;
    opacity: 1;
    z-index: 100;

    &.slick-prev {
      left: 1rem;

      &:before {
        content: url('../../../assets/home/carousel-prev-arrow.svg');
        font-size: 1rem;
      }
    }

    &.slick-next {
      right: 1rem;

      &:before {
        content: url('../../../assets/home/carousel-next-arrow.svg');
        font-size: 1rem;
      }
    }

    &:hover {
      background-color: var(--neutral-color-2);
      opacity: 1;
      z-index: 100;
    }
  }
}
@media (max-width: 1200px) {
  .section-swipe-right.section {
    h2 {
      font-size: 33px;
    }

    h3 {
      font-size: 22.5px;
    }
  }
  .section-swipe-right {
    padding-bottom: 70px;
  }
}

.SwipeRight {
  .slick-slide + div {
    margin-top: 20px;
    @media (max-width: 599px) {
      margin: 0;
    }
  }

  .slick-slide.slick-active.slick-current + div {
    transform: scale(1.1);
    margin: 20px auto 10px auto;
    @media (max-width: 599px) {
      transform: none;
      margin: 0;
    }
  }
  .slick-dots {
    @media (max-width: 599px) {
      // bottom: 15px;
    }
  }
}
