@import './buttons';
@import '../../styles/variables';

.case-profile {
  h3 {
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-color-1);
    margin-top: 20px;
    text-align: center;
  }
}

.case__body {
  display: flex;

  @media only screen and (max-width: 599px) {
    flex-direction: column;
  }
}

.case_container {
  width: 100%;
  background: #edf2ff;
  min-height: 100%;
  // height: 100%;
  // background: orange;
  &-mask-tour {
    opacity: 0.4;
  }
  .hint-modal {
    .custom_modal_content {
      width: auto;
      .card {
        border-radius: 16px;
      }
    }
  }
  .container {
    max-width: 730px;
    position: relative;
    .card {
      border: 0;
      border-bottom: 1.25rem solid #fff;

      -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
      &.no-border {
        border: 0;
      }
      .card-body {
        padding: 2.5rem 6rem;

        @media only screen and (max-width: $bp-medium) {
          padding: 2rem 4rem;
        }

        @media only screen and (max-width: $bp-small) {
          padding: 2rem 2rem;
        }

        @media only screen and (max-width: $bp-smallest) {
          padding: 1rem 1rem;
        }

        .icons {
          display: flex;
          flex-direction: row;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--primary-color-2-o1);
            border-radius: 50%;
            width: 47px;
            height: 47px;
            overflow: hidden;
            margin-right: 1rem;

            @media only screen and (max-width: $bp-small) {
              margin-right: 0.5rem;

              &:last-child {
                display: none;
              }
            }
            div {
              color: var(--secondary-color-2);
            }
          }
        }
        .title h5 {
          color: var(--secondary-color-2);
          text-transform: uppercase;
          font-weight: bold;
          margin: 35px 0;
          font-size: 20px;
        }
        .note {
          color: var(--secondary-color-2);
          font-size: 17px;
        }
      }
    }
    .modal-addon {
      position: absolute;
      top: 0;
      transform: translate(-72%, -42%);

      @media only screen and (max-width: $bp-medium) {
        display: none;
      }
    }
    &.exit,
    &.submit-case {
      max-width: 440px;
      min-width: 440px;
      height: 208px;
      .card {
        border-bottom-width: 0;
        .card-body {
          height: 208px;
          padding: 30px 60px;
          .title {
            h5 {
              font-size: 21px;
              line-height: 1.19;
              text-transform: initial;
              margin-bottom: 15px;
              margin-top: 0;
            }
          }
          .note {
            p {
              font-size: 14px;
              padding: 0 30px;
            }
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding-top: 0;
      }
    }
    &.exit {
      .card {
        .card-body {
          padding: 30px 60px;
        }
      }
    }
    &.hint-modal-container {
      @media only screen and (max-width: $bp-small) {
        width: 26.5rem;
      }

      @media only screen and (max-width: $bp-smallest) {
        width: 19.8125rem;
      }

      .card {
        border-bottom: 1.25rem solid;
        .card-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 50px;
          padding-bottom: 30px;
        }
      }
      .hint-presentation {
        text-align: center;
        margin-bottom: 20px;
      }
      .hint-count {
        color: #0a306e;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 20px;
      }
      .hint-text {
        color: #072b61;
        font-size: 17px;
        font-weight: normal;
        text-align: center;
        text-transform: none;
        padding-bottom: 20px;
      }
      .timeline {
        margin-left: 62px;
        margin-bottom: 30px;
        li {
          padding-left: 35px;
        }
      }
    }
  }
  &-messages-wrapper {
    opacity: 1;
    transition: opacity 0.5s;

    .case_container-main-col {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      transition: all 0.5s ease;

      // @media only screen and (max-width: $bp-smallest) {
      //   margin-top: 62px;
      // }
    }
    .case_container-right-col {
      width: 50%;
      max-width: 23rem;
      padding-right: 0.5rem;
      position: 'relative';
      z-index: 2;
      transition: all 0.5s ease;
      @media only screen and (max-width: 960px) {
        max-width: 18rem;
      }

      @media only screen and (max-width: 599px) {
        max-width: 100%;
        width: 100%;
        order: -1;
        padding-right: 0;
      }

      // @media only screen and (max-width: $bp-smallest) {
      //   position: fixed;
      // }
    }
  }
  &-messages-wrapper.opacity-0 {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .case_container {
    // overflow: auto;
    &-messages-wrapper {
      .main-col {
        width: 100%;
      }
      .right-col {
        width: 100%;
      }
    }
  }
}

.icon__background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2fafd;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;

  // &-none {
  //   @media only screen and (max-width: $bp-small) {
  //     display: none;
  //   }
  // }

  &-sm {
    width: 3rem;
    height: 3rem;
    margin-right: 0.8rem;

    @media only screen and (max-width: $bp-smallest) {
      margin-right: 0.4rem;
    }
  }

  @media only screen and (max-width: $bp-smallest) {
    width: 3rem;
    height: 3rem;
  }
}
