.section-hero-overlay-1 {
  position: absolute;
  left: 0;
  bottom: -40px;
  max-width: 20%;
}

.section-hero-overlay-2 {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 40%;
}
.untiring-patients-overlay-1 {
  position: absolute;
  right: 0;
  bottom: 23px;
  transform: translate(0, 100%);
  max-width: 20%;
}

@media (max-width: 960px) {
  .section-hero-overlay-1,
  .section-hero-overlay-2,
  .untiring-patients-overlay-1 {
    display: none;
  }
}
