.social-wrapper {
  button:first-child {
    margin-right: 20px;
  }
  button {
    box-shadow: none;
    background: none;
    border: none;
    width: 34px;
    height: 34px;
    img {
      width: 100%;
    }
  }
}
