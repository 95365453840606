@import '../../../styles//variables';

.timer {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 17px 63px 17px 14px;
  margin-left: -16px;
  margin-right: 0.5rem;
  box-shadow: 0 2px 30px 0 rgba(27, 28, 29, 0.09);
  border-bottom-right-radius: 57px;
  border-top-right-radius: 57px;
  z-index: 1;

  // New css start here
  @media only screen and (max-width: 599px) {
    display: none;
  }

  &__body {
    display: flex;
    justify-content: space-between;

    &__info {
      display: flex;
      align-items: center;
      width: '100%';

      &-logo {
        height: 2.5rem;
        margin: 0 1.2rem 0 0.5rem;
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          transform: scale(1.1);
        }
      }

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: solid 2px var(--border-color-2);
        padding: 0 1.2rem 0 1.2rem;

        &-wrap {
          cursor: pointer;
          transition: all 0.4s;

          &:hover {
            transform: scale(1.2);
          }
        }
      }

      &-switch {
        display: flex;
        align-items: center;
        &-voice {
          font-size: 14px;
          margin-right: 10px;
        }
        &-text {
          color: var(--secondary-color-2);
          opacity: 0.2;
          font-weight: 500;
          font-size: 12px;
          &-active {
            color: var(--primary-color-1);
            opacity: 1;
          }
        }
      }

      &-person {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #002348;

        &-name {
          font-size: 1.3rem;
          margin-top: -0.4rem;
          font-weight: 500;
          @media only screen and (max-width: $bp-small) {
            font-size: 16px;
          }
        }
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -2rem;

      @media only screen and (max-width: $bp-medium) {
        margin-right: -3rem;
      }

      .btn-primary-color {
        box-shadow: none;
        text-transform: capitalize;
        font-family: 'Roboto', sans-serif;
        width: 16rem;

        @media only screen and (max-width: $bp-medium) {
          width: 9rem;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
  // New css ends here
  .icons {
    display: flex;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    &.hidden-icons {
      opacity: 0;
    }
    .icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: none;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border: 0px;
      font-size: 46px;
    }
    .pause {
      margin-right: 10px;
      margin-left: 10px;
      background-color: #ecf2ff;
      &:hover {
        background-color: #d4d9e5;
      }
    }
    .refresh {
      margin-right: 10px;
      margin-left: 10px;
      background-color: #f2fafd;
      &:hover {
        background-color: #d9e1e3;
      }
      svg {
        overflow: visible;
        margin-left: 5px;
        .new-icons {
          transform: scale(1.4, 1.4);
          stroke: #83d0f0;
        }
      }
    }
    .level {
      background-color: #ecf2ff;
      margin-left: 10px;
    }
    .system {
      background-color: #f2fafd;
      margin-left: 10px;
      img {
        max-height: 58px;
      }
    }
  }
  .timer-action {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .chat-to {
      h5 {
        font-size: 20px;
        font-weight: bold;
        color: var(--secondary-color-2);
        margin: 0;
      }
    }
  }
  .submit-case {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 100%;
  }
  .timebar {
    position: absolute;
    top: 115px;
    width: 50%;

    transition: width 1s;
    -webkit-transition: none;
    .current-process {
      width: 100%;
      height: 7px;
      border-radius: 3.5px;
      background-color: var(--primary-color-1);
      position: relative;
      z-index: 1;
      &.timer-complete {
        background-color: var(--cardiology-color);
      }
    }
    .current-time-indicator {
      position: absolute;
      right: 0;
      width: max-content;
      transform: translateX(50%);
      text-align: center;
      z-index: 8888;
      display: none;
      &.visible {
        display: inherit;
      }
      .triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent var(--secondary-color-1) transparent;
        margin: auto;
      }
      p {
        border-radius: 15px;
        background-color: var(--secondary-color-1);
        margin: 0;
        padding: 3px 15px;
        font-size: 15px;
        line-height: 1.33;
        font-weight: 600;
      }
    }
  }
}
