.backend-error {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  .message-wrapper {
    background-color: white;
    max-width: 90%;
    width: 400px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    animation: slideDown 0.5s ease-in-out;
    button {
      outline: none !important;
      background: none;
      border: none;
      font-size: 20px;
    }
    h3 {
      color: red;
      margin-right: 40px;
      margin-bottom: 0px;
      font-size: 15px;
      text-align: center;
      flex: 1;
    }
    div {
      height: 50px;
      width: 1px;
      background-color: lightgrey;
    }
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
