@keyframes click-animation {
  0% {
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border: 1px solid;
  }
  10% {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 4px solid;
  }
  30% {
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 4px solid;
  }
  70% {
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 8px solid;
  }
  100% {
    top: -12px;
    left: -12px;
    right: -12px;
    bottom: -12px;
    border: 2px solid;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn-scale {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes slideUp-scale {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: none;
  }
}

@keyframes slideUp-100 {
  from {
    transform: translateY(100px);
  }
  to {
    transform: none;
  }
}

@keyframes slideDown-height {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}

@keyframes slideRight-scale {
  from {
    opacity: 1;
    transform: scaleX(1);
  }
  to {
    opacity: 1;
    transform: scaleX(0);
  }
}

@keyframes rotation-open {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

// Fix iOS auto apply first rule
@keyframes rotation-close {
  0% {
    transform: rotate(0deg);
  }
  1% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes scale-large {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
