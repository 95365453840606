.case__level__badge__large {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  color: var(--secondary-color-2);

  &-header {
    font-size: 0.5rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
  }

  &-level {
    font-size: 1rem;
    font-weight: 500;
    margin-top: -0.4rem;
  }
}
