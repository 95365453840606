.maybe-later-link {
  position: absolute;
  width: 100px;
  font-size: 16px;
  font-weight: normal;
  top: 30px;
  left: calc(100% - 130px);
  color: var(--primary-color-1);
  cursor: pointer;
}

.wrapper {
  width: 100%;
  text-align: center;

  .characters {
    width: 100%;
    text-align: center;
    margin-top: 70px;

    img {
      width: 443px;
    }
  }

  h1 {
    color: var(--primary-color-1);
    font-size: 30px;
    font-weight: 600;
    line-height: 1.33;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  h6 {
    color: var(--primary-color-1);
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 20px;
  }

  .link-block {
    margin: 10px auto 10px auto;
    width: 300px;
    display: flex;
    flex-direction: row;
    position: relative;

    &::after {
      content: 'Code copied to clipboard';
      font-size: 10px;
      position: absolute;
      bottom: -12px;
      left: calc(50% - 50px);
      opacity: 0;
      transition: opacity;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
    }
    &.clicked::after {
      opacity: 1;
    }
    div:first-of-type {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      border: 2px solid var(--primary-color-1);

      font-size: 35px;
      line-height: 1.75;
      letter-spacing: 1.85px;
      text-align: center;
      width: 200px;
      color: var(--primary-color-1);
      background-color: var(--background-color);
    }

    div:last-of-type {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      border: 2px solid var(--primary-color-1);
      width: 100px;
      background-color: var(--primary-color-1);
      color: var(--neutral-color-2);
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.2px;
      line-height: 3.3;
      cursor: pointer;
    }

    div {
      height: 64px;
    }
  }

  p {
    margin-top: 10px;
    color: var(--secondary-color-2);
    font-size: 20px;
    line-height: 1.4;
    font-weight: normal;

    span {
      font-weight: 600;
    }
  }
  span.share {
    font-size: 16px;
    line-height: 1.5;
    color: var(--primary-color-2);
    font-weight: bold;
  }
  .social-icons {
    margin-top: 10px;
    svg:first-child {
      margin-right: 20px;
    }
    svg {
      cursor: pointer;
    }
  }
}
